import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, Box, Input, Button, ModalBody, Modal, SattInput, SattButton, SattLogo2 } from '@pancakeswap/uikit'
import { Wallet } from 'ethers'
import { useSattWallet } from '../../../state/satt/hooks'

interface Props {
  onDismiss?: () => void
  confirmSattTransaction?: (password?: string) => void
  ShowSattPasswordModal?: boolean
}

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const SattPasswordModal: React.FC<Props> = ({
  onDismiss = () => null,
  confirmSattTransaction,
  ShowSattPasswordModal,
}) => {
  const [sattPassword, setSattPassword] = useState<string>('')
  const [isWorning, setIsWorning] = useState<boolean>(false)
  const { getSattSigner } = useSattWallet()

  const handlePassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWorning(false)
    setSattPassword(event.target.value)
  }

  const onPressConfirmSatt = () => {
    const signer = getSattSigner(sattPassword)
    if (signer instanceof Wallet) {
      confirmSattTransaction(sattPassword)
      onDismiss()
    } else {
      setIsWorning(true)
    }
  }
  if (ShowSattPasswordModal) {
    return (
      <Box minWidth="320px">
        <ModalBody width={['400px', null, '400px']}>
          <WalletWrapper p="15px" maxHeight="453px" overflowY="auto">
            <Text
              textAlign="center"
              color="#1F2349"
              mb="10px"
              fontSize="16px"
              fontFamily="Poppins-bold"
              fontWeight="400"
              lineHeight="130%"
            >
              Please enter your satt wallet password
            </Text>
            <SattInput
              m="10px"
              onChange={handlePassChange}
              placeholder="Password"
              type="password"
              isWarning={isWorning}
            />
            {isWorning && (
              <Box my="10px">
                <Text color="red" textAlign="center">
                  Incorrect password
                </Text>
              </Box>
            )}
          </WalletWrapper>
          <Box p="24px">
            <SattButton width="100%" onClick={onPressConfirmSatt} bgColor="#7F84FF">
              Confirm Satt Transaction
            </SattButton>
          </Box>
        </ModalBody>
      </Box>
    )
  }
  return (
    <Modal title="Confirm Satt Transaction" headerBackground="gradients.cardHeader" onDismiss={onDismiss}>
      <Box minWidth="320px">
        <ModalBody width={['320px', null, '340px']}>
          <WalletWrapper p="15px" maxHeight="453px" overflowY="auto">
            <Text textAlign="left" color="textSubtle" as="p" mb="5px">
              Please enter your satt wallet password
            </Text>
            <Input m="10px" onChange={handlePassChange} placeholder="Password" type="password" isWarning={isWorning} />
            {isWorning && (
              <Box my="10px">
                <Text color="red" textAlign="center">
                  Incorrect password
                </Text>
              </Box>
            )}
          </WalletWrapper>
          <Box p="24px">
            <Button width="100%" onClick={onPressConfirmSatt}>
              Confirm Satt Transaction
            </Button>
          </Box>
        </ModalBody>
      </Box>
    </Modal>
  )
}

export default SattPasswordModal
